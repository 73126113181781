import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Blockies from "react-blockies";
import { useTranslation } from 'react-i18next';
import { Flex, Box, Text } from "rebass/styled-components";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";

import { ExternalLink } from "theme/components";
import { getEtherscanLink } from "utils/web3";
import { getExplorerLink } from "utils/nebulas";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { FiCopy, FiArrowUpRight } from "react-icons/fi";

const AccountModal = ({ type = "eth", onDismiss, account }) => {
  const { t } = useTranslation();

  const chain_id = Number(process.env.REACT_APP_CHAIN_ID);

  const [copied, setCopied] = useState(false);

  const hanldeClickCopy = () => {
    setCopied(true);
  };

  const renderNetwork = () => {
    if (type === "eth") {
      return chain_id === 1 ? "Ethereum" : "Ethereum Sepolia";
    } else {
      return chain_id === 1 ? "Nebulas": "Nebulas Testnet";
    }
  };

  const getExplorerLabel = () => {
    if (type === "eth") {
      return t("view-on-etherscan");
    } else {
      return t("view-on-explorer");
    }
  };

  const getAccountExplorerLink = () => {
    if (type === "eth") {
      return getEtherscanLink(account);
    } else {
      return getExplorerLink(account);
    }
  };

  const getEventsLink = () => {
    if (type === "eth") {
      return `/event?type=1&key=${account}`;
    } else {
      return `/event?type=2&key=${account}`;
    }
  };

  return (
    <Modal onDismiss={onDismiss}>
      <ModalContent>
        <StyledNetwork>{renderNetwork()}</StyledNetwork>
        <CopyToClipboard text={account}>
          <StyledAccount>
            <StyledWrapper onClick={hanldeClickCopy}>
              <Blockies seed={account} size={10} scale={3} />
              <StyledAccountText>{account}</StyledAccountText>
            </StyledWrapper>
          </StyledAccount>
        </CopyToClipboard>

        {copied ? <StyledMsg>{t("copied-to-clipboard")}</StyledMsg> : null}

        <StyledTextsWrapper>
          <CopyToClipboard text={account}>
            <StyledTextIconWrapper onClick={hanldeClickCopy}>
              <FiCopy />
              <Text>{t("copy-the-address")}</Text>
            </StyledTextIconWrapper>
          </CopyToClipboard>

          <StyledTextIconWrapper>
            <StyledLink href={getAccountExplorerLink()}>
              {getExplorerLabel()}
            </StyledLink>
            <FiArrowUpRight />
          </StyledTextIconWrapper>
        </StyledTextsWrapper>
        <StyledTexts2Wrapper>
          <ExternalLink href={getEventsLink()}>{t("view-swap-events")}</ExternalLink>
        </StyledTexts2Wrapper>
      </ModalContent>
    </Modal>
  );
};

const StyledAccount = styled.div`
  padding: 6px 0;
`;

const StyledNetwork = styled(Text)``;

const StyledMsg = styled(Text)`
  color: ${({ theme }) => theme.color.success};
  padding: 5px 0 0;
`;

const StyledWrapper = styled(Flex)`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.color.border1};
  align-items: center;
  cursor: pointer;
`;
const StyledAccountText = styled(Text)`
  margin-left: 10px;
  font-size: 12px;
  overflow-wrap: break-word;
  color: ${({ theme }) => theme.color.text1};
`;

const StyledTextsWrapper = styled(Flex)`
  justify-content: space-between;
  padding: 12px 0;
`;

const StyledTextIconWrapper = styled(Flex)`
  font-size: 14px;
  color: ${({ theme }) => theme.color.primary.main};
  align-items: center;
  cursor: pointer;

  & > svg {
    margin: 0 6px;
  }
`;

const StyledLink = styled(ExternalLink)`
  font-size: 14px;
  font-weight: 400;
`;

const StyledTexts2Wrapper = styled(Flex)`
  padding: 12px 6px;
  border-top: 1px solid ${({ theme }) => theme.color.border1};
`;

export default AccountModal;
