import React, { createContext, useEffect, useState } from "react";
import { useWallet } from "use-wallet";

import { Link } from "link";
import { INFURA_PREFIXES } from "utils/web3";

export const Context = createContext({
  link: undefined,
});

const LinkProvider = ({ children }) => {
  const [link, setLink] = useState();
  const { ethereum } = useWallet();

  const chain_id = parseInt(process.env.REACT_APP_CHAIN_ID);

  const getInfuraProvider = (t) => {
    if (ethereum) return ethereum;

    const network = INFURA_PREFIXES[chain_id];

    const array = process.env.REACT_APP_INFURA_ID.split(",");
    const index = t % array.length;
    const infuraId = array[index];
    return `https://${network}infura.io/v3/${infuraId}`;
  };

  useEffect(() => {
    const linkIns = new Link(getInfuraProvider, chain_id, false, {
      defaultAccount: "",
      defaultConfirmations: 1,
      autoGasMultiplier: 1.5,
      testing: false,
      defaultGas: "6000000",
      defaultGasPrice: "1000000000000",
      accounts: [],
      ethereumNodeTimeout: 10000,
    });
    setLink(linkIns);
    // window.sodasauce = linkIns;
  }, [chain_id, ethereum]);

  return <Context.Provider value={{ link }}>{children}</Context.Provider>;
};

export default LinkProvider;
