import React from "react";
import styled from "styled-components";
import { Flex, Box, Text } from "rebass/styled-components";
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from "utils/format";


const TokenInput = ({
  type,
  value,
  balance = 0,
  fetching = true,
  onChange,
  network,
  networkType,
  tokenList,
}) => {

  const { t } = useTranslation();

  const chain_id = Number(process.env.REACT_APP_CHAIN_ID);

  // const renderType = (_type) => {
  //   return t();
  // };

  const onInputChange = (e) => {
    const value = e.target.value;
    // check value is number
    if (isNaN(value)) {
      return false;
    }

    onChange(value);
  };

  const setInputMax = () => {
    if(balance) {
      onChange(balance);
    }
  }

  return (
    <StyleCard>
      <StyleCardHead>
        <StyleCardHeadText>{t(type)}</StyleCardHeadText>

        <StyleCardHeadText>
          <StyledNetworkText>
            {network} {chain_id !== 1 ? networkType : ""}
          </StyledNetworkText>
          <StyledBalance onClick={setInputMax}>
          {type === "from" &&
            !fetching &&
            `${t('balance')}: ${balance} ${tokenList[0].name}`}
          </StyledBalance>
          
        </StyleCardHeadText>
      </StyleCardHead>

      <StyledContent>
        <StyledInput
          type="text"
          value={value}
          onChange={(e) => onInputChange(e)}
          disabled={type === "to" ? "disabled" : ""}
          placeholder="0.0"
        ></StyledInput>

        <StyledTokenItem key={tokenList[0].id}>
          <StyledTokenLogo src={tokenList[0].logo}></StyledTokenLogo>
          <StyledTokenText>{tokenList[0].name}</StyledTokenText>
        </StyledTokenItem>
      </StyledContent>
    </StyleCard>
  );
};

const StyleCard = styled(Flex)`
  flex-direction: column;
  padding: 15px 20px;
  border: 1px solid ${({ theme }) => theme.color.border1};
`;

const StyleCardHead = styled(Flex)`
  justify-content: space-between;
`;

const StyleCardHeadText = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.color.text1};
`;

const StyledContent = styled(Flex)`
  justify-content: space-between;
  padding-top: 12px;
`;

const StyledBalance = styled.span`
  cursor:pointer;
`

const StyledInput = styled.input`
  border: none;
  outline: none;
  height: 30px;
  font-size: 22px;
  color: ${({ theme }) => theme.color.text1};

  &:disabled {
    background-color: transparent;
  }
`;

const StyledTokenItem = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

const StyledTokenLogo = styled.img`
  width: 20px;
  height: 20px;
`;

const StyledTokenText = styled(Text)`
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  margin: 0 5px;
  color: ${({ theme }) => theme.color.text1};
`;

const StyledNetworkText = styled(Text)`
  font-size: 12px;
  text-align: right;
  color: ${({ theme }) => theme.color.text2};
  text-transform: capitalize;
`;

export default TokenInput;
