import React, { useContext, useMemo } from "react";
import styled, { ThemeContext } from "styled-components";

import { Link } from "react-router-dom";

const Button = ({
  children,
  disabled,
  href,
  onClick,
  size,
  text,
  to,
  variant,
}) => {
  const { color, spacing } = useContext(ThemeContext);

  let buttonColor;
  let buttonBgColor;
  let buttonBorderColor;

  switch (variant) {
    case "secondary":
      buttonColor = color.primary.main;
      buttonBorderColor = color.primary.main;
      buttonBgColor = "#fff";
      break;
    case "primary":
    case "default":
    default:
      buttonColor = color.white;
      buttonBorderColor = color.primary.main;
      buttonBgColor = color.primary.main;
  }

  let buttonSize;
  let buttonPadding;
  let fontSize;
  switch (size) {
    case "sm":
      buttonPadding = spacing[3];
      buttonSize = 36;
      fontSize = 14;
      break;
    case "lg":
      buttonPadding = 18;
      buttonSize = 56;
      fontSize = 15;
      break;
    case "md":
    default:
      buttonPadding = spacing[4];
      buttonSize = 56;
      fontSize = 16;
  }

  const ButtonChild = useMemo(() => {
    if (to) {
      return <StyledLink to={to}>{text}</StyledLink>;
    } else if (href) {
      return (
        <StyledExternalLink href={href} target="__blank">
          {text}
        </StyledExternalLink>
      );
    } else {
      return text;
    }
  }, [href, text, to]);

  return (
    <StyledButton
      color={buttonColor}
      bgColor={buttonBgColor}
      borderColor={buttonBorderColor}
      disabled={disabled}
      fontSize={fontSize}
      onClick={onClick}
      padding={buttonPadding}
      size={buttonSize}
    >
      {children}
      {ButtonChild}
    </StyledButton>
  );
};

const StyledButton = styled.button`
  align-items: center;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "inherit")};
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "none")};
  color: ${(props) => (!props.disabled ? props.color : `${props.color}55`)};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 700;
  height: ${(props) => props.size}px;
  justify-content: center;
  outline: none;
  padding-left: ${(props) => props.padding}px;
  padding-right: ${(props) => props.padding}px;
  pointer-events: ${(props) => (!props.disabled ? undefined : "none")};
  width: ${(props) => (props.size === 36 ? "auto" : "100%")};
  &:hover {
    background-color: ${(props) => props.theme.color.primary.main};
  }

  &:disabled {
    background-color: ${(props) =>
      props.bgColor ? `${props.bgColor}aa` : "inherit"};
    border: none;
    color: #fff;
  }
`;

const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-decoration: none;
`;

const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-decoration: none;
`;

export default Button;
