import useLink from "hooks/useLink";
import { stake } from "utils/nebLink";
import { toNasBasic } from "utils/nebFormat";


const useNebStake = () => {
  const link = useLink();

  const handleStake = async (
    ethAddr,
    amount,
    link_contract_id = "LINK_PROXY",
    token_id = "WNAS"
  ) => {


    // detect if use nas nano
    // open qrcode scan modal
    // use neb chrome extension to call contract
    // use nebulas to call swap
    if (link) {
      const res = await stake(
        link.neb,
        link_contract_id,
        token_id,
        ethAddr,
        toNasBasic(amount)
      );

      console.log("neb stake", res);

      return res;
    }
  };

  return { onNebStake: handleStake };
};

export default useNebStake;
