import { Neb, HttpRequest, Unit } from "nebulas";
import NebPay from "nebpay.js";

// neb chain id
const neb_chain_id = Number(process.env.REACT_APP_NEB_CHAIN_ID);

const MAINNET_CHAIN_ID = 1;
const TESTNET_CHAIN_ID = 1001;

// neb network map
const NEB_NETWORK = {
  [MAINNET_CHAIN_ID]: "mainnet",
  [TESTNET_CHAIN_ID]: "testnet",
};

const CALL_API_FROM_ADDR = "n1KSYoLoqen2bYmZvUC3JXT9TeXrAhkzrPy";

// set gas price, limit default
const GAS = {
  PRICE: "20000000000",
  LIMIT: "2000000",
  LIMIT_HIGH: "8000000",
};

const getContract = () => {
  return {
    host: `https://${NEB_NETWORK[neb_chain_id]}.nebulas.io`,
    chain_id: neb_chain_id,
    call_api_from: CALL_API_FROM_ADDR,
  };
};

const { chain_id, host, call_api_from } = getContract();

export const EXPLORER_PREFIXES = {
  [MAINNET_CHAIN_ID]: "#/",
  [TESTNET_CHAIN_ID]: "#/testnet/",
};

export function getExplorerLink(data, type) {
  const prefix = "https://explorer.nebulas.io";

  switch (type) {
    case "tx": {
      return `${prefix}/${EXPLORER_PREFIXES[neb_chain_id]}tx/${data}`;
    }

    case "address":
    default: {
      return `${prefix}/${EXPLORER_PREFIXES[neb_chain_id]}address/${data}`;
    }
  }
}

export class Nebulas {
  constructor() {
    // init neb
    const neb = new Neb();
    neb.setRequest(new HttpRequest(host));

    // init nebpay
    const nebPay = new NebPay();

    this.neb = neb;
    this.nebPay = nebPay;
  }

  // read contract: call net function
  call(to_contract, func, args = [], value = 0) {
    return this.neb.api.call({
      chainID: chain_id,
      from: call_api_from,
      to: to_contract,
      value,
      gasPrice: GAS.PRICE,
      gasLimit: GAS.LIMIT,
      contract: {
        function: func,
        args: JSON.stringify(args),
      },
    });
  }

  // write contract: use neb pay chrome extension to post function
  send(to_contract, func, args, value = 0) {
    return new Promise((resolve, reject) => {
      this.nebPay.call(to_contract, value, func, JSON.stringify(args), {
        extension: {
          openExtension: true,
        },
        gasPrice: GAS.PRICE,
        gasLimit: GAS.LIMIT_HIGH,
        // debug: true,
        listener: (serialNumber, resp) => {
          try {
            console.log("serialNumber", serialNumber);
            console.log("resp", resp);
            resolve(serialNumber);
          } catch (err) {
            reject(err);
          }
        },
      });
    });
  }
}

export const getUserAddress = () =>
  new Promise((resolve, reject) => {
    if ("NasExtWallet" in window) {
      window.NasExtWallet.getUserAddress((addr) => {
        resolve(addr);
      });
    } else {
      resolve(null);
    }
  });
