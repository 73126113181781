export const white = "#FFF";
export const black = "#000";

export const green = {
  500: "#28a745",
};

export const red = {
  100: "#FFFDFE",
  200: "#FFA8C7",
  400: "#D63635", // soda band color
  500: "#dc3545",
};

export const grey = {
  100: "#f7f2f4",
  200: "#F0E7EA",
  300: "#e2cfd5",
  400: "#AA8592",
  500: "#80495D",
  600: "#5B2639",
  800: "#2D121B",
};

export const yellow = {
  500: "#FFD040",
};

export const brown = {
  500: "#2A0D0D",
};

export const purple = {
  500: "#732FC5",
};
