import {
  black,
  red,
  grey,
  green,
  yellow,
  brown,
  white,
  purple,
} from "./colors";
import { css } from "styled-components";

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 600,
  upToMedium: 960,
  upToLarge: 1280,
};

const mediaWidthTemplates = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    accumulator[size] = (a, b, c) => css`
      @media (max-width: ${MEDIA_WIDTHS[size]}px) {
        ${css(a, b, c)}
      }
    `;
    return accumulator;
  },
  {}
);

const theme = {
  borderRadius: 12,
  //   breakpoints: {
  //     mobile: 400,
  //   },
  mobile: 400,
  color: {
    black,
    grey,
    yellow,
    brown,
    red,
    green,
    success: green[500],
    error: red[500],
    primary: {
      disabled: "rgba(115, 47, 197, 0.36)",
      main: purple[500],
    },
    secondary: {
      main: brown[500],
    },
    white,
    text1: "#333333",
    text2: "#666666",
    text3: "#999999",

    bg1: "#FFFFFF",
    bg2: "#F7F8FA",
    bg3: "#EDEEF2",
    bg4: "#CED0D9",
    bg5: "#888D9B",
    shadow1: "#2F80ED",

    border1: "#F2F2F2",
  },

  // purples
  wisteriaPurple: "#46bf89",

  // media queries
  mediaWidth: mediaWidthTemplates,

  // css snippets
  flexColumnNoWrap: css`
    display: flex;
    flex-flow: column nowrap;
  `,
  flexRowNoWrap: css`
    display: flex;
    flex-flow: row nowrap;
  `,

  //specialty colors
  modalBG: "rgba(0,0,0,0.3)",
  advancedBG: "rgba(255,255,255,0.6)",

  siteWidth: 1200,
  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },
  topBarSize: 72,
  layout: {
    small: 768,
  },
};

export default theme;
