import React from "react";
import styled from "styled-components";
import { Flex, Box, Text } from "rebass/styled-components";
import Button from "components/Button";

const WalletCard = ({ icon, onConnect, title }) => (
  <StyledCard onClick={onConnect}>
    <StyledIcon src={icon} />
    <StyledText>{title}</StyledText>
  </StyledCard>
);

const StyledCard = styled(Flex)`
  align-items: center;
  padding: 22px 24px;
  border: 1px solid ${({ theme }) => theme.color.border1};
  cursor: pointer;
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.color.text1};
  font-size: 18px;
  font-weight: bold;
  margin-left: 22px;
`;

const StyledIcon = styled.img`
  width: 42px;
  height: auto;
`;

export default WalletCard;
