import Web3 from "web3";
import ERC20ABI from "constants/abi/erc20.json";

// get contract instance
export const getContract = (provider, address) => {
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(ERC20ABI, address);
  return contract;
};

// get token allowance
export const getAllowance = async (proxyContract, tokenContract, account) => {
  try {
    const allowance = await tokenContract.methods
      .allowance(account, proxyContract.options.address)
      .call();

      // console.log("ETH getAllowance", allowance, account, proxyContract.options.address)
    return allowance;
  } catch (e) {
    return "0";
  }
};

// get token balance
export const getBalance = async (tokenContract, account) => {
  try {
    const balance = await tokenContract.methods.balanceOf(account).call();
    // console.log("ETH getBalance", balance, account);
    return balance;
  } catch (e) {
    console.error(e);
    return "0";
  }
};
