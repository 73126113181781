import React from "react";
import styled from "styled-components";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { FiSearch } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const FilterInput = ({ select, fetchData, address, setAddress }) => {

  const { t } = useTranslation("events");

  const handleSearch = () => {
    fetchData({ type: select.type, key: address });
  };

  const handleKeyPress = (target) => {
    // if type 'enter'
    if (target.charCode === 13) {
      handleSearch();
    }
  };

  const onChange = (e) => {
    console.log("onChange", e.target.value);
    setAddress(e.target.value);
  };

  return (
    <StyledWrapper>
      <InputGroup>
        <FormControl
          placeholder={t("ethereum/nebulas-address")}
          aria-label={t("ethereum/nebulas-address")}
          aria-describedby="basic-addon"
          value={address}
          onChange={onChange}
          onKeyPress={handleKeyPress}
        />

        <InputGroup.Append>
          <Button variant="outline-secondary" onClick={handleSearch}>
            <FiSearch />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 500px;

  button {
    border-radius: 0;
  }

  input {
    border-radius: 0;
    &:focus {
    }
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
  width: 100%;
  margin-top: 20px;
`}
`;

export default FilterInput;
