import React, { useState } from "react";
import styled from "styled-components";
import { Flex, Box, Text } from "rebass/styled-components";
import { useTranslation } from 'react-i18next';
import AddressInput from "components/AddressInput";

const RecipientAddrInput = ({ type = "neb", value, onChange, setInvalid }) => {

  const { t } = useTranslation();
  return (
    <StyleCard>
      <StyleCardHead>
        <StyleCardHeadText>{t("recipient-address")}</StyleCardHeadText>
      </StyleCardHead>

      <AddressInput
        type={type}
        value={value}
        onChange={onChange}
        setInvalid={setInvalid}
      />
    </StyleCard>
  );
};

const StyleCard = styled(Flex)`
  flex-direction: column;
  padding: 15px 20px;
  border: 1px solid ${({ theme }) => theme.color.border1};
`;

const StyleCardHead = styled(Flex)``;

const StyleCardHeadText = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.color.text1};
`;

export default RecipientAddrInput;
