import { useCallback } from "react";
import { useWallet } from "use-wallet";
import { stake, getContractById } from "utils/web3Link";
import useLink from "hooks/useLink";

const useStake = (proxyContractId) => {
  const { account } = useWallet();

  const link = useLink();
  let proxyContract;

  if (link) {
    proxyContract = getContractById(link, proxyContractId);
  }

  const handleStake = useCallback(
    async (amount, toNebAddr) => {
      console.log("stake", amount, toNebAddr, account);
      try {
        const tx = await stake(proxyContract, amount, toNebAddr, account);
        return tx.transactionHash;
      } catch (e) {
        return false;
      }
    },
    [account, proxyContract]
  );

  return { onStake: handleStake };
};

export default useStake;
