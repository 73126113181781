import Web3 from "web3";
import { Contracts } from "./web3Lib/contracts.js";
import { Nebulas } from "utils/nebulas";
// import { Account } from "./lib/accounts.js";

export class Link {
  constructor(getProvider, networkId, options) {
    // set random infura provider
    let t = new Date().getTime();
    let provider = getProvider(t);
    let realProvider;

    if (typeof provider === "string") {
      if (provider.includes("wss")) {
        realProvider = new Web3.providers.WebsocketProvider(
          provider,
          options.ethereumNodeTimeout || 10000
        );
      } else {
        realProvider = new Web3.providers.HttpProvider(
          provider,
          options.ethereumNodeTimeout || 10000
        );
      }
    } else {
      realProvider = provider;
    }

    this.web3 = new Web3(realProvider);

    if (options.defaultAccount) {
      this.web3.eth.defaultAccount = options.defaultAccount;
    }

    // init ether contracts instance
    this.contracts = new Contracts(realProvider, this.web3, options);

    // init nebulas instance
    this.neb = new Nebulas();
  }

  setProvider(provider, networkId) {
    this.web3.setProvider(provider);
    this.contracts.setProvider(provider, networkId);
    this.operation.setNetworkId(networkId);
  }

  setDefaultAccount(account) {
    this.web3.eth.defaultAccount = account;
    this.contracts.setDefaultAccount(account);
  }

  getDefaultAccount() {
    return this.web3.eth.defaultAccount;
  }
}
