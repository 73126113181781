import { useContext } from "react";
import { Context } from "contexts/NebProvider";

const useNebAccount = () => {
  const { nebAccount, useNano, onConnect, onDisconnect } = useContext(Context);
  return {
    nebAccount,
    useNano,
    onConnect,
    onDisconnect,
  };
};

export default useNebAccount;
