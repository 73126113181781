import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import TranscationModal from "components/TranscationModal";
import { Text } from "rebass";
import { CloseIcon, Spinner, ExternalLink } from "theme/components";
import { AlertTriangle, ArrowUpCircle } from "react-feather";
import Button from "components/Button";
import { RowBetween } from "components/Row";
import { AutoColumn, ColumnCenter } from "components/Column";
import Circle from "assets/loader.svg";
import { useWallet } from "use-wallet";

import { getEtherscanLink } from "utils/web3";
// import { useActiveWeb3React } from '../../hooks'

const Wrapper = styled.div`
  width: 100%;
`;
const Section = styled(AutoColumn)`
  padding: 24px;
`;

const BottomSection = styled(Section)`
  background-color: ${({ theme }) => theme.bg2};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 60px 0;
`;

const CustomLightSpinner = styled(Spinner)`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

function ConfirmationPendingContent({ onDismiss = () => {}, pendingText }) {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <ConfirmedIcon>
          <CustomLightSpinner src={Circle} alt="loader" size={"90px"} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={"center"}>
          <Text fontWeight={500} fontSize={20}>
            {t("waiting-for-confirm")}
          </Text>
          {pendingText && (
            <AutoColumn gap="12px" justify={"center"}>
              <Text fontWeight={600} fontSize={14} color="" textAlign="center">
                {pendingText}
              </Text>
            </AutoColumn>
          )}
          <Text fontSize={12} color="#565A69" textAlign="center">
            {t("confirm-trx-in-wallet")}
          </Text>
        </AutoColumn>
      </Section>
    </Wrapper>
  );
}

function TransactionSubmittedContent({ onDismiss = () => {}, chainId, hash }) {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <ConfirmedIcon>
          <ArrowUpCircle
            strokeWidth={0.5}
            size={90}
            color={theme.color.success}
          />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={"center"}>
          <Text fontWeight={500} fontSize={20}>
            {t("trx-submitted")}
          </Text>

          {chainId && hash && (
            <ExternalLink href={getEtherscanLink(hash, "tx")}>
              <Text fontWeight={500} fontSize={14}>
                {t("view-on-etherscan")}
              </Text>
            </ExternalLink>
          )}
          <Button onClick={onDismiss}>
            <Text fontWeight={500} fontSize={20}>
            {t("close")}
            </Text>
          </Button>
        </AutoColumn>
      </Section>
    </Wrapper>
  );
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent,
}) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            {title}
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        {topContent()}
      </Section>
      <BottomSection gap="12px">{bottomContent()}</BottomSection>
    </Wrapper>
  );
}

export function TransactionErrorContent({ message, onDismiss }) {
  const theme = useContext(ThemeContext);
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            Error
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <AutoColumn
          style={{ marginTop: 20, padding: "2rem 0" }}
          gap="24px"
          justify="center"
        >
          <AlertTriangle
            color={theme.red1}
            style={{ strokeWidth: 1.5 }}
            size={64}
          />
          <Text
            fontWeight={500}
            fontSize={16}
            color={theme.red1}
            style={{ textAlign: "center", width: "85%" }}
          >
            {message}
          </Text>
        </AutoColumn>
      </Section>
      <BottomSection gap="12px">
        <Button onClick={onDismiss}>Dismiss</Button>
      </BottomSection>
    </Wrapper>
  );
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
}) {
  //   const { chainId } = useActiveWeb3React()
  const { chainId } = useWallet();

  if (!chainId) return null;

  // confirmation screen
  return (
    <TranscationModal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      {attemptingTxn && (
        <ConfirmationPendingContent
          onDismiss={onDismiss}
          pendingText={pendingText}
        />
      )}

      {hash && (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
        />
      )}
    </TranscationModal>
  );
}
