import React, { useState } from "react";
import styled from "styled-components";
import { Flex, Box, Text } from "rebass/styled-components";
import Blockies from "react-blockies";
import { formatAddress } from "utils/format";
import AccountModal from "components/AccountModal";

import useModal from "hooks/useModal";

const AccountButton = ({ type, account }) => {
  const [onShowEthAccountModal] = useModal(
    <AccountModal type="eth" account={account} />
  );

  const [onShowNebAccountModal] = useModal(
    <AccountModal type="neb" account={account} />
  );

  const handleOnClick = () => {
    if (type === "eth") {
      onShowEthAccountModal();
    }
    if (type === "neb") {
      onShowNebAccountModal();
    }
  };

  return account ? (
    <StyledWrapper onClick={handleOnClick}>
      <Blockies seed={account} size={10} scale={3} />
      <StyledAccountText>{account}</StyledAccountText>
    </StyledWrapper>
  ) : null;
};

const StyledWrapper = styled(Flex)`
  padding: 10px;
  margin-top: 1rem;
  border: 1px solid ${({ theme }) => theme.color.border1};
  align-items: center;
  cursor: pointer;
`;
const StyledAccountText = styled(Text)`
  margin-left: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.color.text1};
`;

export default AccountButton;
