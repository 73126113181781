import React, { Suspense, useCallback, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { ThemeProvider } from "styled-components";
import { UseWalletProvider } from "use-wallet";

import LinkProvider from "contexts/LinkProvider";
import ModalsProvider from "contexts/Modals";
import NebProvider from "contexts/NebProvider";

import theme from "./theme";
import styled from "styled-components";

import TopBar from "./components/TopBar";
import Footer from "./components/Footer";
import MobileMenu from "./components/MobileMenu";

// views
import Home from "./views/Home";
import Event from "./views/Event";
import bg from "assets/bg.png";

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  background-image: url(${bg});
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #f5f6fa;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    height: auto;
  `}
`;

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);

function App() {
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleDismissMobileMenu = useCallback(() => {
    setMobileMenu(false);
  }, [setMobileMenu]);

  const handlePresentMobileMenu = useCallback(() => {
    setMobileMenu(true);
  }, [setMobileMenu]);

  return (
    <Suspense fallback={null}>
    <Providers>
      <AppWrapper>
        <Router>
          <TopBar onPresentMobileMenu={handlePresentMobileMenu} />
          <MobileMenu
            onDismiss={handleDismissMobileMenu}
            visible={mobileMenu}
          />
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/nas-to-eth" exact>
              <Home direction="nas-to-eth" />
            </Route>

            <Route path="/event" exact>
              <Event />
            </Route>
          </Switch>
          <Footer />
        </Router>
      </AppWrapper>
    </Providers>
    </Suspense>

  );
}

const Providers = ({ children }) => {
  const chain_id = Number(process.env.REACT_APP_CHAIN_ID);

  return (
    <ThemeProvider theme={theme}>
      <UseWalletProvider
        chainId={chain_id}
        connectors={{
          walletconnect: { rpcUrl: "https://mainnet.eth.aragon.network/" },
        }}
      >
        <NebProvider>
          <LinkProvider>
            <ModalsProvider>{children}</ModalsProvider>
          </LinkProvider>
        </NebProvider>
      </UseWalletProvider>
    </ThemeProvider>
  );
};

export default App;
