// ether address map
export const addressMap = {
  1: {
    USDT: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    USDT_PROXY: "0x9F7C10518392BaB3171F0e1786DC3e2b8c5827d2",
    WNAS: "0x9aeBeCdC5829dc6b6Dd1708f3EE732c515b74C63",
    WNAS_PROXY: "0x28C30d24211Bd314F0bf4EFb2b5FB2544B2e6592",
  },
  42: {
    USDT: "0x53FAE6878086eA050cB0eB6322bD425C1774F675",
    USDT_PROXY: "0x6f0116ddF2764c119e846b3292Be06Aa7Ca5522D",
  },
  11155111: {
    USDT: "0x53FAE6878086eA050cB0eB6322bD425C1774F675",
    USDT_PROXY: "0x6f0116ddF2764c119e846b3292Be06Aa7Ca5522D",
    WNAS: "0x9aeBeCdC5829dc6b6Dd1708f3EE732c515b74C63",
    WNAS_PROXY: "0x28C30d24211Bd314F0bf4EFb2b5FB2544B2e6592",
  },
};

// nebulas address map
export const NebAddressMap = {
  1: {
    NUSDT: "n1tV52CtfmV4f9W6txBYDXCBE5ChC1afEa5",
    WNAS: "n1jrKxgPEUcs7BU2vq3jBzNBdTwWH1oFXku",
    LINK_PROXY: "n1pcp6NQZZh1dymeS5RiqDDGQqh4U96xjZv",
  },
  1001: {
    NUSDT: "n1prbivQy5kwQ3WU9RdzRFPifJwprUrDyTQ",
    LINK_PROXY: "n1m6a1VSUPzzTuuv1kXwCsNbh744CJYFVEC",
  },
};
