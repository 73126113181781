import React from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

import { ExternalLink } from "theme/components";

const FooterFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-top: 2rem;
    background-color: #fff;
  `}
`;

const FooterElement = styled.div`
  margin: 1.25rem;
  display: flex;
  min-width: 0;
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }

  & > a {
    margin-right: 1rem;
  }
`;

export default function Footer() {

  const { t } = useTranslation();
  return (
    <FooterFrame>
      <FooterElement>
        <Title>
          <ExternalLink href="https://swap.nextdao.io/">
            NextDAO.Swap
          </ExternalLink>

          <ExternalLink href={t("document-url")}>
            {t("document")}
          </ExternalLink>

          <ExternalLink href="https://github.com/nebulasio/link-contract">
          {t("contracts")}
          </ExternalLink>
        </Title>
      </FooterElement>
    </FooterFrame>
  );
}
