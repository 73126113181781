import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Flex, Box, Text } from "rebass/styled-components";
import logo from "assets/nextdao@2x.png";
import { NavLink } from "react-router-dom";
import Container from "components/Container";

const TopBar = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <Container layout="top">
        <StyledTopBar>
          <StyledLogoWrapper>
            <StyledLink
              target="__blank"
              rel="noreferrer noopener"
              href="https://nextdao.io/"
              exact
              to="/"
            >
              <StyledLogo src={logo} />
            </StyledLink>

            <StyledNavLink exact to="/">
              <StyledText>Link</StyledText>
            </StyledNavLink>
          </StyledLogoWrapper>
          <Box mx="auto" />
          <StyledNavs>
            <StyledNavItem exact activeClassName="active" to="/event">
              {t("events")}
            </StyledNavItem>
          </StyledNavs>
        </StyledTopBar>
      </Container>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(134, 141, 167, 0.2);
`;

const StyledTopBar = styled(Flex)`
  height: 70px;
`;

const StyledLogoWrapper = styled(Flex)`
  align-items: center;
`;

const StyledLogo = styled.img`
  height: 30px;
  width: 134px;
`;

const StyledText = styled(Text)`
  margin-left: 20px;
  color: ${({ theme }) => theme.color.primary.main};

`;

const StyledNavs = styled(Flex)`
  align-items: center;
`;

const StyledNavItem = styled(NavLink)`
  margin: 0 22px;
  text-decoration: none;
  color: ${({ theme }) => theme.color.text1};

`;

const StyledLink = styled.a`
  height: 30px;
`;

const StyledNavLink = styled(NavLink)`
  &:hover {
    text-decoration: none;
  }
`

export default TopBar;
