import React from "react";
import styled from "styled-components";
import { Flex, Box, Text } from "rebass/styled-components";
// import useBridgeFee from "hooks/useBridgeFee";
import { useTranslation } from 'react-i18next';

const SwapInfo = ({ direction = "eth-to-nas", feeData }) => {
  const { t } = useTranslation();
  // get eth gas price, 
  // const { feeData } = useBridgeFee();

  return (
    <StyledWrapper>
      <StyledPrice>
        <StyledPriceTitle>{t("price")}</StyledPriceTitle>

        {direction === "eth-to-nas" && (
          <StyledPriceText>1 NAS = 1 WNAS</StyledPriceText>
        )}

        {direction === "nas-to-eth" && (
          <StyledPriceText>1 WNAS = 1 NAS</StyledPriceText>
        )}
      </StyledPrice>
      <StyledFee>
        {direction === "eth-to-nas" && (
          <StyledFeeText>{t("fee")} = 0 NAS</StyledFeeText>
        )}

        {direction === "nas-to-eth" && (
          <StyledFeeText>{t("fee")} ≈ {feeData ? `${feeData.feeUSD} WNAS` : '-'} </StyledFeeText>
        )}
        {/* <StyledFeeText>
          Ethereum gas fee: please reserve at least 0.001 ETH
        </StyledFeeText> */}
      </StyledFee>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Flex)`
  flex-direction: column;
  padding: 10px 8px;
`;

const StyledPrice = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 5px;
`;

const StyledPriceTitle = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.color.text1};
`;

const StyledPriceText = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.color.text1};
`;

const StyledFee = styled(Flex)`
  flex-direction: column;
  align-items: flex-end;
`;

const StyledFeeText = styled(Text)`
  font-size: 14px;
  color: ${({ theme }) => theme.color.text1};
`;

export default SwapInfo;
