import { useState } from "react";
import { fetchEvents } from "utils/api";

const useEvent = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [fetching, setFetching] = useState(true);

  const fetchEventData = async ({ page = 0, type = 1, key = "" }) => {
    setFetching(true);

    const result = await fetchEvents({ page, type, key });
    if (result) {
      console.log("fetchEvents", result);
      setData(result.list);
      setTotal(result.count);
    }

    setFetching(false);
  };

  return { eventData: data, totalEvent: total, fetchEventData, fetching };
};

export default useEvent;
