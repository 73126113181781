import React from "react";
import styled from "styled-components";
import { Flex, Box, Text } from "rebass/styled-components";
import { ExternalLink } from "theme/components";

const WalletCard = ({ icon, onConnect = () => {}, title, href }) => {
  if (href) {
    return (
      <StyledLink href={href}>
        <StyledCard>
          <StyledIcon src={icon} />
          <StyledText>{title}</StyledText>
        </StyledCard>
      </StyledLink>
    );
  } else {
    return (
      <StyledCard onClick={onConnect}>
        <StyledIcon src={icon} />
        <StyledText>{title}</StyledText>
      </StyledCard>
    );
  }
};

const StyledCard = styled(Flex)`
  align-items: center;
  padding: 22px 24px;
  border: 1px solid ${({ theme }) => theme.color.border1};
  cursor: pointer;
`;

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.color.text1};
  font-size: 16px;
  font-weight: bold;
  margin-left: 22px;
`;

const StyledIcon = styled.img`
  width: 42px;
  height: auto;
`;

const StyledLink = styled(ExternalLink)`
  &:hover {
    text-decoration: none;
  }
`;

export default WalletCard;
