import React, { createContext, useCallback, useState } from "react";
import { getUserAddress } from "utils/nebulas";

export const Context = createContext({
  nebAccount: null,
  chainId: null,
  useNano: false,
  onConnect: () => {},
  onDisconnect: () => {},
});

const NebProvider = ({ children }) => {
  const chainId = parseInt(process.env.REACT_APP_NEB_CHAIN_ID);
  const [account, setAccount] = useState(null);
  const [isUseNano, setIsUseNano] = useState(false);

  //   const [connector, setConnector] = useState(null);
  //   const [error, setError] = useState(null);
  //   const [status, setStatus] = useState("disconnected");

  const handleConnect = useCallback(
    async (address = "", isNano = false) => {
      let addr = address;
      if (!address) {
        addr = await getUserAddress();
      }
      console.log("getUserAddress", addr);
      console.log("isNano", isNano);
      setAccount(addr);
      setIsUseNano(isNano);
      return addr;
    },
    [setAccount]
  );

  const handleDisconnect = useCallback(() => {
    setAccount(null);
  }, [setAccount]);

  return (
    <Context.Provider
      value={{
        nebAccount: account,
        chainId,
        useNano: isUseNano,
        onConnect: handleConnect,
        onDisconnect: handleDisconnect,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default NebProvider;
