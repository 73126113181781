import { useCallback, useEffect, useState } from "react";

import BigNumber from "bignumber.js";
import { useWallet } from "use-wallet";
import useLink from "hooks/useLink";
import { getAllowance } from "utils/erc20";
import { getContractById } from "utils/web3Link";

const useAllowance = (proxyContractId, tokenContractId) => {
  const [allowance, setAllowance] = useState(new BigNumber(0));
  const { account } = useWallet();

  const link = useLink();
  let proxyContract, tokenContract;

  //   console.log("link", link);
  if (link) {
    proxyContract = getContractById(link, proxyContractId);
    tokenContract = getContractById(link, tokenContractId);
  }

  const fetchAllowance = useCallback(async () => {
    const allowance = await getAllowance(proxyContract, tokenContract, account);
    setAllowance(new BigNumber(allowance));
  }, [account, tokenContract, proxyContract]);

  useEffect(() => {
    if (account && tokenContract && proxyContract) {
      fetchAllowance();
    }
    let refreshInterval = setInterval(fetchAllowance, 10 * 1000);
    return () => clearInterval(refreshInterval);
  }, [account, tokenContract, proxyContract, fetchAllowance]);

  return allowance;
};

export default useAllowance;
