import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Flex, Box, Text } from "rebass/styled-components";
import QRCode from "qrcode.react";

import { toNasBasic } from "utils/nebFormat";
import { nebAddressMap } from "utils/nebLink";

import { ExternalLink } from "theme/components";

// images
import appstoreIcon from "assets/download-on-the-appstore.png";
import googlePlayIcon from "assets/get-it-on-google-play.png";

// modal
import Modal from "components/Modal";
import ModalTitle from "components/ModalTitle";
import ModalContent from "components/ModalContent";

const CallContractModal = ({ onDismiss, contractParams, title = "" }) => {
  const { t } = useTranslation();
  const {
    to = nebAddressMap["LINK_PROXY"],
    func,
    args,
    value = 0,
  } = contractParams;

  function genQrcodeStr() {
    const qrcodeStr = JSON.stringify({
      pageParams: {
        pay: {
          currency: "NAS",
          value: toNasBasic(value),
          to,
          gasLimit: "800000",
          gasPrice: "20000000000",
          payload: {
            function: func,
            args: JSON.stringify(args),
            type: "call",
          },
        },
      },
      des: "confirmTransfer",
      category: "jump",
    });

    console.log("qrcode", qrcodeStr);
    return qrcodeStr;
  }

  return (
    <Modal onDismiss={onDismiss}>
      <ModalTitle text={title} />
      <ModalContent>
        <StyledWrapper>
          <StyledQRCode>
            <QRCode size={256} value={genQrcodeStr()} />
          </StyledQRCode>

          <StyledNanoWrapper>
            <h3>{t("use-nano-scan")}</h3>
            <StyledAppDownload>
              <a
                href=" https://itunes.apple.com/us/app/nas-nano-pro/id1441776793&mt=8"
                target="__blank"
              >
                <img alt="appstore" src={appstoreIcon} />
              </a>
              <a
                href=" https://play.google.com/store/apps/details?id=io.nebulas.wallet.android&hl=en_US"
                target="__blank"
              >
                <img alt="google-play" src={googlePlayIcon} />
              </a>
            </StyledAppDownload>
          </StyledNanoWrapper>
        </StyledWrapper>
      </ModalContent>
    </Modal>
  );
};

const StyledWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
`;

const StyledNanoWrapper = styled(Flex)`
  flex-direction: column;
  padding-top: 18px;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 1rem;
  }
`;

const StyledAppDownload = styled(Flex)`
  img {
    width: auto;
    height: 54px;
    padding: 6px;
  }
`;

const StyledQRCode = styled(Flex)`
  justify-content: center;
`;

export default CallContractModal;
