import React, { useState, useEffect } from "react";
import styled from "styled-components";
import nasLogo from "assets/nas.png";
import nanoLogo from "assets/nanopro.png";
import googleLogo from "assets/google.png";
import githubLogo from "assets/github.png";
import { useTranslation } from 'react-i18next';

import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalTitle from "components/ModalTitle";

import WalletCard from "./components/WalletCard";

import AssociateAddressInput from "./components/AssociateAddressInput";

import useNebAccount from "hooks/useNebAccount";

import { FiArrowLeft } from "react-icons/fi";

const NebWalletProviderModal = ({ onDismiss, onConnectedCallback }) => {
  const { t } = useTranslation();

  const [page, setPage] = useState("index");

  const { nebAccount, onConnect } = useNebAccount();

  useEffect(() => {
    if (nebAccount) {
      onDismiss();
      onConnectedCallback(nebAccount);
    }
  }, [nebAccount, onDismiss, onConnectedCallback]);

  const hanldeClickChromeExtension = async () => {
    const acc = await onConnect();
    if (acc) {
      console.log("connected", await nebAccount);
    } else {
      // not install chrome extension
      setPage("install-chrome-extension");
    }
  };

  const handleBackToIndex = () => {
    setPage("index");
  };

  const handleUseNasnano = () => {
    setPage("use-nas-nano");
  };

  return (
    <Modal onDismiss={onDismiss}>
      {page === "index" ? (
        <ModalTitle text={t("select-an-nebulas-wallet")} />
      ) : (
        <StyledBack onClick={handleBackToIndex}>
          <FiArrowLeft />
        </StyledBack>
      )}

      {page === "index" && (
        <ModalContent>
          <StyledWalletsWrapper>
            <StyledWalletCard>
              <WalletCard
                icon={nasLogo}
                onConnect={hanldeClickChromeExtension}
                title={t("nebulas-chrome-extension")}
              />
            </StyledWalletCard>

            <StyledWalletCard>
              <WalletCard
                icon={nanoLogo}
                onConnect={handleUseNasnano}
                title="Nas Nano Pro"
              />
            </StyledWalletCard>
          </StyledWalletsWrapper>
        </ModalContent>
      )}

      {page === "install-chrome-extension" && (
        <ModalContent>
          <StyledWalletCard>
            <WalletCard
              icon={googleLogo}
              href="https://chrome.google.com/webstore/detail/nasextwallet/gehjkhmhclgnkkhpfamakecfgakkfkco"
              title={t("google-store-download")}
            />
          </StyledWalletCard>

          <StyledWalletCard>
            <WalletCard
              icon={githubLogo}
              href="https://github.com/nebulasio/WebExtensionWallet/releases"
              title={t("github-download")}
            />
          </StyledWalletCard>
        </ModalContent>
      )}

      {page === "use-nas-nano" && (
        <ModalContent>
          <AssociateAddressInput />
        </ModalContent>
      )}
    </Modal>
  );
};

const StyledWalletsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledWalletCard = styled.div`
  padding: 6px 0;
`;

const StyledBack = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.color.text2};
  cursor: pointer;
`;

export default NebWalletProviderModal;
