import { useCallback } from "react";
import { useWallet } from "use-wallet";
import { approve, getContractById } from "utils/web3Link";
import useLink from "hooks/useLink";

const useApprove = () => {
  const { account } = useWallet();

  const link = useLink();

  const handleApprove = useCallback(
    async (proxyContractId, tokenContractId) => {
      let proxyContract, tokenContract;

      if (link) {
        proxyContract = getContractById(link, proxyContractId);
        tokenContract = getContractById(link, tokenContractId);
      }

      try {
        const tx = await approve(proxyContract, tokenContract, account);
        return tx.transactionHash;
      } catch (e) {
        return false;
      }
    },
    [link, account]
  );

  return { onApprove: handleApprove };
};

export default useApprove;
