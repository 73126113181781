import React from "react";
import styled from "styled-components";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";


const FilterTab = ({ networkList, select, onSelect, address, fetchData }) => {

    const { t } = useTranslation("events");

  const renderSelect = (type) => {
    if (type === 1) {
      return `${t("ethereum")}${t("to")}${t("nebulas")}`;
    }

    if (type === 2) {
      return `${t("nebulas")}${t("to")}${t("ethereum")}`;
    }
  };

  const handleClick = (network) => {
    onSelect(network);
    fetchData({ key: address, type: network.type });
  };

  return (
    <StyledWrapper>
      <ButtonGroup>
        {networkList.map((network) => (
          <Button
            onClick={() => handleClick(network)}
            variant={select.id === network.id ? "light active" : "light"}
            key={network.id}
          >
            {renderSelect(network.type)}
          </Button>
        ))}
      </ButtonGroup>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div``;

export default FilterTab;
