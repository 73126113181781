import { Unit, Account } from "nebulas";

// NAS
export const basicToNas = (value) => Unit.fromBasic(value, "nas");
export const toNasBasic = (value) => Unit.toBasic(value, "nas").toString(10);
export const toLocaleNas = (value) => basicToNas(value).toLocaleString();

// NAX
export const basicToNax = (value) => Unit.fromBasic(value, "gwei");
export const toNaxBasic = (value) => Unit.toBasic(value, "gwei").toString(10);
export const toLocaleNax = (value) => basicToNax(value).toLocaleString();

// nUSDT
export const basicToNUSDT = (value) => Unit.fromBasic(value, "mwei");
export const toNUSDTBasic = (value) => Unit.toBasic(value, "mwei").toString(10);

// validate neb address
export const isNebValidAddress = (value) => {
  return Account.isValidAddress(value);
  //   return /^n1\w{33}$/.test(value);
};
