import React from 'react'
import styled from "styled-components"


import swap from "assets/icon/swap.png";

const SwitchDirectionButton = ({onSwitchClick}) => {
    return (
        <StyledButton onClick={onSwitchClick}>
            <StyledSwapLogo src={swap} />
        </StyledButton>
    )
}

const StyledButton = styled.button`
    outline: none;
    background: none;
    border: none;
    padding: 12px 20px;
    width: 40px;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`

const StyledSwapLogo = styled.img`
    width: auto;
    height: 20px;
`

export default SwitchDirectionButton
