import React from "react";
import styled, { keyframes } from "styled-components";

import { MdClose } from "react-icons/md";

const Modal = ({ children, onDismiss }) => {
  return (
    <StyledResponsiveWrapper>
      <StyledModal>
        <StyleCloseButton onClick={onDismiss}>
          <MdClose />
        </StyleCloseButton>
        {children}
      </StyledModal>
    </StyledResponsiveWrapper>
  );
};

const mobileKeyframes = keyframes`
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
`;

const StyledResponsiveWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  max-width: 460px;
  @media (max-width: ${(props) => props.theme.mobile}px) {
    flex: 1;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    max-height: calc(100% - ${(props) => props.theme.spacing[4]}px);
    animation: ${mobileKeyframes} 0.3s forwards ease-out;
  }
`;

const StyledModal = styled.div`
  background: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px 0px rgba(134, 141, 167, 0.15);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 0;
`;

const StyledModalContent = styled.div``;

const StyleCloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  line-height: 1;
  font-size: 24px;
  color: ${({ theme }) => theme.color.text2};
`;

export default Modal;
