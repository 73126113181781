import BigNumber from "bignumber.js";

export const bnToDec = (bn, decimals = 18) => {
  return bn.dividedBy(new BigNumber(10).pow(decimals)).toNumber();
};

export const decToBn = (dec, decimals = 18) => {
  return new BigNumber(dec).multipliedBy(new BigNumber(10).pow(decimals));
};

export const getDisplayBalance = (balance, decimals = 18, precision = 2) => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals));
  if (displayBalance.lt(0.001)) {
    return displayBalance.toPrecision(6);
  } else if (displayBalance.lt(1)) {
    return displayBalance.toPrecision(4);
  } else {
    return displayBalance.toFixed(precision);
  }
};

export const getFullDisplayBalance = (balance, decimals = 18) => {
  return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed();
};

export const toLocaleString = (value) => {
  const n = Number(value);
  if (n < 0.01) {
    return n.toString();
  } else {
    return n.toLocaleString();
  }
};
