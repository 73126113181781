import React, { useMemo } from "react";
import BigNumber from "bignumber.js";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import useNebAccount from "hooks/useNebAccount";
import useNebAllowance from "hooks/useNebAllowance";
import useNebApprove from "hooks/useNebApprove";
import useNebBalance from "hooks/useNebBalance";
import useNebLatestTransferAccount from "hooks/useNebLatestTransferAccount";
import useNebStake from "hooks/useNebStake";
// modal
import useModal from "hooks/useModal";
import NebWalletProviderModal from "components/NebWalletProviderModal";
import CallContractModal from "components/CallContractModal";

// utils
import { nebAddressMap } from "utils/nebLink";
import { toNasBasic } from "utils/nebFormat";

const NebSwapButton = ({ params }) => {
  const {
    recpAddr,
    fromValue,
    inputDisabled,
    recpAddrInvalid,
    inputError,
  } = params;

  const { t } = useTranslation();

  const { nebAccount, useNano } = useNebAccount();
  const { nebAllowance, fetchNebAllowance } = useNebAllowance(nebAccount);
  const { onNebApprove } = useNebApprove();
  const { nebBalance, fetchNebBalance } = useNebBalance(nebAccount);
  const { fetchNebLatestTransferAccount } = useNebLatestTransferAccount();

  const { onNebStake } = useNebStake();

  const [onPresentNebWalletProviderModal] = useModal(
    <NebWalletProviderModal
      onConnectedCallback={fetchNebLatestTransferAccount}
    />
  );

  const [onShowApproveModal] = useModal(
    <CallContractModal
      title={`${t("approve")} WNAS`}
      contractParams={{
        func: "approve",
        to: nebAddressMap["WNAS"],
        args: [
          nebAddressMap["LINK_PROXY"],
          nebAllowance,
          toNasBasic(nebBalance),
        ],
      }}
    />
  );

  const [onShowSwapModal] = useModal(
    <CallContractModal
      title={t("swap")}
      contractParams={{
        func: "stake",
        args: ["WNAS", recpAddr, toNasBasic(fromValue)],
      }}
    />
  );

  const handleClickApprove = () => {
    // get latest allowance, balance
    fetchNebAllowance();
    fetchNebBalance();

    // detect if use nas nano link account
    // show call contract modal
    if (useNano) {
      onShowApproveModal();
    } else {
      // use chrome extension
      onNebApprove(nebAllowance, nebBalance);
    }
  };

  const handleClickSwap = () => {
    // swap eth token => neb token

    // detect if use nas nano link account
    // show call contract modal

    if (useNano) {
      onShowSwapModal();
    } else {
      onNebStake(recpAddr, fromValue);
    }
  };

  const needApprove = useMemo(() => {
    if (nebAllowance && nebBalance && fromValue) {
      const allowanceBig = new BigNumber(nebAllowance);
      const balanceBig = new BigNumber(toNasBasic(nebBalance));
      const fromValueBig = new BigNumber(toNasBasic(fromValue));

      if (allowanceBig.lt(fromValueBig) && fromValueBig.lte(balanceBig)) {
        return true;
      }
    }
    return false;
  }, [nebAllowance, nebBalance, fromValue]);

  return (
    <div>
      {!nebAccount && (
        <Button
          size="lg"
          onClick={onPresentNebWalletProviderModal}
          text={t("connect-nebulas-wallet")}
        />
      )}

      {/* debug */}
      {/* {!useNano ? "no" : "yes"} <br></br>
      {toNUSDTBasic(fromValue)}
      <br></br>
      {nebAllowance}
      <br></br>
      {toNUSDTBasic(nebBalance)} */}


      {!!nebAccount &&
        (needApprove ? (
          <Button
            size="lg"
            onClick={handleClickApprove}
            text={`${t("approve")} WNAS`}
          />
        ) : (
          <Button
            size="lg"
            onClick={handleClickSwap}
            disabled={inputDisabled || recpAddrInvalid ? "disabled" : null}
            text={inputError ? inputError : t("swap")}
          />
        ))}
    </div>
  );
};

export default NebSwapButton;
