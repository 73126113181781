import React from "react";
import TokenInput from "components/TokenInput";

import nusdt from "assets/icon/nusdt.png";
import wnas from "assets/icon/wnas.png";

const NebTokenInput = ({ type, value, onChange, balance, fetching }) => {
  const chain_id = Number(process.env.REACT_APP_CHAIN_ID);

  const tokenList = [
    {
      id: "wnas",
      name: "WNAS",
      logo: wnas,
    },
    {
      id: "nusdt",
      name: "nUSDT",
      logo: nusdt,
    },
  ];

  return (
    <TokenInput
      type={type}
      onChange={onChange}
      value={value}
      balance={balance}
      fetching={fetching}
      network="nebulas"
      networkType={chain_id === 1 ? "mainnet" : "testnet"}
      tokenList={tokenList}
    />
  );
};

export default NebTokenInput;
