import React, { useState } from "react";
import styled from "styled-components";
import { Flex, Box, Text } from "rebass/styled-components";
import { useTranslation } from "react-i18next";
import AddressInput from "components/AddressInput";

import Button from "components/Button";
import useNebAccount from "hooks/useNebAccount";

const AssociateAddressInput = () => {
  const { t } = useTranslation();
  const [address, setAddress] = useState("");
  const [invalid, setInvalid] = useState(true);

  const { onConnect } = useNebAccount();

  const onChange = (value) => {
    setAddress(value);
  };

  const handleAssociate = () => {
    onConnect(address, true);
  };

  return (
    <StyledWrapper>
      <StyledInputWrapper>
        <StyledText>{t("associate-nebulas-address")}</StyledText>
        <StyledAddrInputWrapper>
        <AddressInput
            type="neb"
            value={address}
            setInvalid={setInvalid}
            onChange={onChange}
          />
        </StyledAddrInputWrapper>
          
      </StyledInputWrapper>

      <Button disabled={invalid} onClick={handleAssociate}>
        {t("associate")}
      </Button>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Flex)`
  flex-direction: column;
`;
const StyledInputWrapper = styled(Flex)`
  flex-direction: column;
  margin-bottom: 12px;

`;

const StyledAddrInputWrapper = styled.div`
  border: ${(props) => `1px solid ${props.theme.color.border1}`};
  padding: 0 12px 12px;
`;

const StyledText = styled(Text)`
  margin-bottom: 6px;
`;

export default AssociateAddressInput;
