import React, { useState } from "react";
import styled from "styled-components";
import { Flex, Box, Text } from "rebass/styled-components";
import { useTranslation } from 'react-i18next';
// utils
import { isNebValidAddress } from "utils/nebFormat";
import { isETHAddress } from "utils/web3";

const AddressInput = ({
  border = false,
  type = "neb",
  value = "",
  onChange = () => {},
  setInvalid = () => {},
}) => {

  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState("");

  const onInputChange = (e) => {
    const value = e.target.value;
    onChange(value);

    const invalid = checkRecpInputValid(value);

    setInvalid(invalid);
  };

  const renderInputPlaceholder = () => {
    if (type === "neb") {
      return t("nebulas-address-placeholder");
    } else {
      // type === "eth"
      return t("ethereum-address");
    }
  };

  const checkRecpInputValid = (addr) => {
    let invalid = false;
    setErrorMsg("");
    if (type === "neb") {
      console.log("checkRecpInputValid", addr, isNebValidAddress(addr));

      if (!addr) {
        invalid = true;
        setErrorMsg(t("please-input-nebulas-address"));
      } else if (!isNebValidAddress(addr)) {
        invalid = true;
        setErrorMsg(t("invalid-address"));
      }
    } else {
      // type === eth
      // is addr is valid ether address
      console.log("eth checkRecpInputValid", addr, isETHAddress(addr));

      if (!addr) {
        invalid = true;
        setErrorMsg(t("please-input-ethereum-address"));
      } else if (!isETHAddress(addr)) {
        invalid = true;
        setErrorMsg(t("invalid-address"));
      }
    }

    return invalid;
  };

  return (
    <StyleWrapper border={border}>
      <StyledContent>
        <StyledInput
          type="text"
          value={value}
          onChange={(e) => onInputChange(e)}
          placeholder={renderInputPlaceholder()}
        ></StyledInput>

        {errorMsg ? <StyledError>{errorMsg}</StyledError> : null}
      </StyledContent>
    </StyleWrapper>
  );
};

const StyleWrapper = styled(Flex)`
  flex-direction: column;
  border: ${(props) =>
    props.border ? `1px solid ${props.theme.color.border1}` : "none"};
`;

const StyledContent = styled(Flex)`
  padding-top: 12px;
  flex-direction: column;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 14px;
  color: ${({ theme }) => theme.color.text1};

  &::placeholder {
    color: #999;
  }

  &:disabled {
    background-color: transparent;
  }
`;

const StyledError = styled(Text)`
  color: red;
  font-size: 13px;
`;

export default AddressInput;
