import moment from "moment";

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function formatAddress(address = "", number = 6) {
  return address.slice(0, number) + "..." + address.slice(-number);
}

export const toDatetime = (datetime) => {
  return moment(datetime).format("L");
};

export const toUSD = (value) => {
  return value ? Number((value / 10**6).toFixed(2)) : 0;
}

export const toNAS = (value) => {
  return value ? Number((value / 10**18).toFixed(2)) : 0;
}