import { useCallback, useEffect, useState } from "react";
import useLink from "hooks/useLink";
import { balanceOf } from "utils/nebLink";
import { basicToNas } from "utils/nebFormat";

const useNebBalance = (nebAccount) => {
  const [nebBalance, setBalance] = useState(0);
  const [fetchingBalance, setFetchingBalance] = useState(true);

  const link = useLink();

  const fetchNebBalance = useCallback(
    async (token_contract_id = "WNAS") => {
      if (nebAccount) {
        const balance = await balanceOf(
          link.neb,
          token_contract_id,
          nebAccount
        );

        const balanceVal = basicToNas(balance);
        setBalance(balanceVal);
        setFetchingBalance(false);
      }
    },
    [nebAccount, link]
  );

  useEffect(() => {
    if (link) {
      fetchNebBalance();
    }
    let refreshInterval = setInterval(fetchNebBalance, 10 * 1000);
    return () => clearInterval(refreshInterval);
  }, [link, fetchNebBalance]);

  return { nebBalance, fetchingNebBalance: fetchingBalance, fetchNebBalance };
};

export default useNebBalance;
