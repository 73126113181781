import { useCallback, useEffect, useState } from "react";
import useLink from "hooks/useLink";
import { getLatestTransferAddress } from "utils/nebLink";

const useLatestTransferAddress = () => {
  const [ethAccount, setEthAccount] = useState("");
  const [fetching, setFetching] = useState(true);

  const link = useLink();

  const fetchNebLatestTransferAccount = useCallback(
    async (nebAccount, link_contract_id = "LINK_PROXY") => {
      if (nebAccount) {
        const acc = await getLatestTransferAddress(
          link.neb,
          link_contract_id,
          nebAccount
        );

        console.log("getLatestTransferAddress", acc);

        setEthAccount(acc);
        setFetching(false);
      }
    },
    [link]
  );

  return { ethAccount, fetchNebLatestTransferAccount };
};

export default useLatestTransferAddress;
