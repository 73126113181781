import React, { useContext } from "react";
import styled, { css, ThemeContext } from "styled-components";

const Container = ({ children, size = "lg", layout }) => {
  const { siteWidth } = useContext(ThemeContext);
  let width;
  switch (size) {
    case "sm":
      width = siteWidth / 2;
      break;
    case "md":
      width = (siteWidth * 2) / 3;
      break;
    case "lg":
    default:
      width = siteWidth;
  }
  return (
    <StyledContainer layout={layout} width={width}>
      {children}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: ${(props) => props.width}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  width: 100%;

  ${({ layout }) =>
    layout === "body" &&
    css`
      display: flex;
      width: 100%;
      flex: 1;
      justify-content: center;
      min-height: calc(100vh - 134px);
    `}
`;

export default Container;
