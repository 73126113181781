import erc20ABI from "constants/abi/erc20.json";
import nTokenControllerABI from "constants/abi/nTokenController.json";

import { getAddressMap } from "./constants.js";

export class Contracts {
  constructor(provider, web3, options) {
    this.web3 = web3;

    // load abi
    this.usdt = new this.web3.eth.Contract(erc20ABI);
    this.usdt_proxy = new this.web3.eth.Contract(nTokenControllerABI);

    this.wnas = new this.web3.eth.Contract(erc20ABI);
    this.wnas_proxy = new this.web3.eth.Contract(nTokenControllerABI);

    // set contracts deploy address
    this.addressMap = getAddressMap();

    // set provider
    this.setProvider(provider);

    // set account
    this.setDefaultAccount(this.web3.eth.defaultAccount);
  }

  setProvider(provider) {
    const contracts = [
      {
        contract: this.usdt,
        address: this.addressMap["USDT"],
      },
      {
        contract: this.usdt_proxy,
        address: this.addressMap["USDT_PROXY"],
      },
      {
        contract: this.wnas,
        address: this.addressMap["WNAS"],
      },
      {
        contract: this.wnas_proxy,
        address: this.addressMap["WNAS_PROXY"],
      },
    ];

    contracts.forEach((c) =>
      this.setContractProvider(c.contract, c.address, provider)
    );
  }

  setDefaultAccount(account) {
    this.usdt.options.from = account;
    this.usdt_proxy.options.from = account;

    this.wnas.options.from = account;
    this.wnas_proxy.options.from = account;
  }

  setContractProvider(contract, address, provider) {
    contract.setProvider(provider);
    try {
      contract.options.address = address;
    } catch (error) {
      console.log(error);
    }
  }
}
