import React from "react";
import styled from "styled-components";

const ModalTitle = ({ text }) => <StyledModalTitle>{text}</StyledModalTitle>;

const StyledModalTitle = styled.div`
  align-items: center;
  color: ${(props) => props.theme.color.text1};
  display: flex;
  padding: 0;
  font-size: 18px;
  height: 30px;
  font-weight: 700;
`;

export default ModalTitle;
