import { getNebAddressMap } from "link/neb3Lib/constants.js";

export const nebAddressMap = getNebAddressMap();

// approve token to spender contract
export const approve = async (
  neb,
  token_contract_id,
  spender_contract_id,
  currentValue,
  value
) => {
  const token_contract = nebAddressMap[token_contract_id];
  const spender_contract = nebAddressMap[spender_contract_id];

  const args = [spender_contract, currentValue, value];
  console.log("approve args", args);

  try {
    const res = await neb.send(token_contract, "approve", args);
    console.log("approve res", res);
    return res;
  } catch (e) {
    console.log(e);
  }
};

// get token allowance
export const getAllowance = async (
  neb,
  token_contract_id,
  spender_contract_id,
  owner
) => {
  const token_contract = nebAddressMap[token_contract_id];
  const spender_contract = nebAddressMap[spender_contract_id];

  const args = [owner, spender_contract];

  try {
    const allowance = await neb.call(token_contract, "allowance", args);

    console.log("neb allowance", allowance.result, args);
    return JSON.parse(allowance.result);
  } catch (e) {
    console.log(e);
  }
};

// get address balance
export const balanceOf = async (neb, token_contract_id, address) => {
  const token_contract = nebAddressMap[token_contract_id];

  const args = [address];

  try {
    const balance = await neb.call(token_contract, "balanceOf", args);
    console.log("neb balanceOf", balance, token_contract_id);

    return JSON.parse(balance.result);
  } catch (e) {
    console.log(e);
  }
};

// stake, swap neb token to ether token
export const stake = async (
  neb,
  link_contract_id,
  token_id,
  ethAddr,
  amount
) => {
  const link_contract = nebAddressMap[link_contract_id];

  const args = [token_id, ethAddr, amount];
  const res = await neb.send(link_contract, "stake", args);
  console.log("stake", res);
  return res;
};

// get default eth recipient address
export const getLatestTransferAddress = async (
  neb,
  link_contract_id,
  address
) => {
  const link_contract = nebAddressMap[link_contract_id];
  const args = [address];

  try {
    const acc = await neb.call(link_contract, "getMappingAccount", args);

    console.log("getMappingAccount res", acc.result);
    return JSON.parse(acc.result);
  } catch (e) {
    console.log("getMappingAccount err", e);
  }
};
